import { render, staticRenderFns } from "./UserCardList.vue?vue&type=template&id=d071c6be&scoped=true&"
import script from "./UserCardList.vue?vue&type=script&lang=js&"
export * from "./UserCardList.vue?vue&type=script&lang=js&"
import style0 from "./UserCardList.vue?vue&type=style&index=0&id=d071c6be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d071c6be",
  null
  
)

export default component.exports